import CtvDialogPopup from '@camtv/front-base-ui/lit/dialog-popup'
import axios from 'axios'

document.addEventListener('DOMContentLoaded', () => {

    // Imposta il submit dei form
    let vforms = document.querySelectorAll("form:not(#CONTACT_US)")
    vforms.forEach((form) => {
        try {

            let button = form.querySelector("button")
            form.onsubmit = (evt) => {
                evt.preventDefault()

                let endpoint = form.getAttribute("data-endpoint")
                let modal = form.getAttribute("data-modal")
                if (endpoint == null || endpoint == "")
                    return;

                button.setAttribute("disabled", "disabled")
                button.style.minWidth = button.getBoundingClientRect().width + "px"
                let ih = button.innerHTML
                button.innerHTML = `<i class="fa fa-spinner fa-spin"></i>`;

                let data = {}
                form.querySelectorAll("[data-param]").forEach((input) => {
                    let key = input.getAttribute("data-param")
                    data[key] = input.value
                })

                let options = {
                    headers: {}
                };
                if (document.querySelector('input[type="file"]')) {
                    data = new FormData(form)
                    options.headers["Content-Type"] = "multipart/form-data";
                }



                axios.post(endpoint, data, options).then(async (ret) => {
                    CtvDialogPopup.ShowMessageBox("{%T|Grazie|%}", "{%T|La tua richiesta è stata inoltrata con successo. Ti contatteremo nel più breve tempo possibile.|%}")
                    // window.modal = new bootstrap.Modal(document.getElementById(modal))
                    // window.modal.show()
                }).catch((err) => {

                }).finally(() => {
                    button.removeAttribute("disabled")
                    button.innerHTML = ih
                    form.querySelectorAll("[data-param]").forEach((input) => {
                        if (input.type != "hidden")
                            input.value = ""
                    })
                })
            }

        }
        catch (Ex) {
            console.error(Ex)
        }
    })

})