import "@camtv/front-base-css/main"

import ContactUsDialog from './contact_us_dialog'
import CtvLegalDialog from '@camtv/front-base-ui/lit/legal-dialog'
import './forms_handler'
import GDPRModule from "@3technologysrl/front-gdpr"

window.addEventListener('DOMContentLoaded', () => {

    document.querySelectorAll('.ContactUsCTA').forEach((el) => {
        el.onclick = (evt) => {
            let page = el.getAttribute("data-page");
            let subject = el.getAttribute("data-subject");
            let title = el.getAttribute("data-title");
            let intro = el.getAttribute("data-intro");
            let message = el.getAttribute("data-message");
            evt.preventDefault();
            ContactUsDialog.Show(document.querySelector('#CONTACT_US'), page, subject, title, intro, message);
        }
    })

    document.querySelectorAll(".CookiePolicy").forEach((el) => el.onclick = (evt) => {
        evt.preventDefault();
        CtvLegalDialog.ShowCookies("https://static.3technology.it/legal/cookies.md")
    })
    document.querySelectorAll(".PrivacyPolicy").forEach((el) => el.onclick = (evt) => {
        evt.preventDefault();
        CtvLegalDialog.ShowPrivacy("https://static.3technology.it/legal/privacy.md")
    })

});