import axios from 'axios'
import Validate from '@camtv/front-base-lib/utilities/validate'
import { html, render } from 'lit-html'
import CtvDialogModal from '@camtv/front-base-ui/lit/dialog-modal'
import CtvDialogPopup from '@camtv/front-base-ui/lit/dialog-popup'
import Utilities from '@camtv/front-base-lib/utilities/utilities'

export default class ContactUsDialog extends CtvDialogModal {

    static get DomTag() { return 'ctv-contact-us-dialog'; }

    constructor() {
        super();

        this.show_back_link = true
        this.form = null

        this.SetLeftLink(null)
        this.SetRightLink(null)
        this.SetButton(null)
    }

    Init() {
        let dlgHinstance = this;
        ContactUsDialog.InitContactUsDialog(this.querySelector("form"), dlgHinstance).then(() => { this.Ok() })
    }

    RenderFooter() {
        return html``;
    }

    async Show() {
        let el = document.createElement("div")
        let node = this.form
        let rnd = Utilities.GetRandomStringStartingWithLetter(10)
        node.querySelectorAll("label").forEach((el) => {
            let f = el.getAttribute("for")
            if (node.querySelector("#" + f) != null) {
                node.querySelector("#" + f).setAttribute("id", rnd + f)
                el.setAttribute("for", rnd + f)
            }
        })

        el.append(node)
        this.body = this.ToHtml(el.innerHTML)

        return super.Show()
    }

    static async Show(form, page, subject, title, intro, message) {
        let dlg = new ContactUsDialog()
        dlg.form = form.cloneNode(true)
        if (page) {
            let pageEl = document.createElement("input");
            pageEl.setAttribute("type", "hidden");
            pageEl.setAttribute("data-field", "page");
            pageEl.setAttribute("value", page);
            dlg.form.prepend(pageEl);
        }
        if (subject) {
            let subjectEl = document.createElement("input");
            subjectEl.setAttribute("type", "hidden");
            subjectEl.setAttribute("data-field", "subject");
            subjectEl.setAttribute("value", subject);
            dlg.form.prepend(subjectEl);
        }
        if (title)
            dlg.form.querySelector('h3').innerHTML = title;

        if (intro){
            let introelement = document.createElement('p');
            introelement.innerHTML = intro;
            dlg.form.querySelector('.box-title').append(introelement);
        }
        
        dlg.page = page;
        dlg.subject = subject;
        dlg.message = message;
        setTimeout(()=>{            
            document.querySelector('ctv-contact-us-dialog input[type=text]').focus();
            console.log("asd")
        },500);
        await dlg.Show();        
    }

    static InitContactUsDialog(form, dlgHinstance) {
        let btn = form.querySelector("button")
        let vFields = form.querySelectorAll("[data-field]")
        vFields.forEach((el) => {
            el.addEventListener("focus", (evt) => {
                vFields.forEach((fld) => {
                    if (fld.closest(".ctv-input-field") != null)
                        fld.closest(".ctv-input-field").classList.remove("error")
                })
            })
        })

        if (form.querySelectorAll(".COOKIE") != null)
            form.querySelectorAll(".COOKIE").forEach((el) => el.onclick = (evt) => {
                evt.preventDefault(); CtvLegalDialog.ShowCookies("https://static.3technology.it/legal/cookies.md")
            })

        if (form.querySelectorAll(".PRIVACY") != null)
            form.querySelectorAll(".PRIVACY").forEach((el) => el.onclick = (evt) => {
                evt.preventDefault(); CtvLegalDialog.ShowPrivacy("https://static.3technology.it/legal/privacy.md")
            })

        // let acceptCheckbox = form.querySelector(".AcceptTermsPrivacy");
        // acceptCheckbox.onchange = () => {
        //     if (acceptCheckbox.checked)
        //         form.querySelector(".accept-legal").classList.remove("error");
        // }

        if (dlgHinstance.message && dlgHinstance.message != "")
            form.querySelector("textarea").value = `${dlgHinstance.message}`;
        else if (dlgHinstance.subject && dlgHinstance.subject != "")
            form.querySelector("textarea").value = `{%T|Ciao, sono interessato a|%} ${dlgHinstance.subject}`;

        return new Promise((resolve, reject) => {
            form.onsubmit = async (evt) => {
                evt.preventDefault()

                let verify_ok = Validate.ValidateForm(form, false, true, (fld, err) => {
                    let cif = fld.closest(".ctv-input-field")
                    if (cif == null)
                        return
                    cif.querySelector(".error").innerText = err
                    cif.classList.add("error")
                })

                if (verify_ok != true)
                    return

                // if (form.querySelector(".AcceptTermsPrivacy").checked == false) {
                //     form.querySelector(".accept-legal .error").innerText = "Spunta la casella per procedere"
                //     form.querySelector(".accept-legal").classList.add("error");
                //     return;
                // }

                const data = {}
                vFields.forEach((el) => {
                    let el_val = el.value.trim()
                    if (el.type == "checkbox")
                        el_val = el.checked

                    data[el.getAttribute("data-field")] = el_val
                })

                try {
                    vFields.forEach((el) => { el.setAttribute("readonly", "true") })
                    btn.classList.add("loading")
                    let respo = await axios.post(form.getAttribute("data-submit-api-url"), data)
                    if (respo.data.toLowerCase() != "accepted")
                        throw ("response.ok != true")

                    if (form.getAttribute("data-submit-redirect-url"))
                        document.location.href = form.getAttribute("data-submit-redirect-url")

                    await CtvDialogPopup.ShowMessageBox("{%T|Ti ringraziamo.|%}", "{%T|La tua richiesta è stata inoltrata con successo. Ti contatteremo nel più breve tempo possibile.|%}")

                    setTimeout(() => {
                        resolve()
                    }, 1000)

                    return
                }
                catch (Ex) {
                    console.log(Ex)

                    await CtvDialogPopup.ShowMessageBox("{%T|Errore|%}", "{%T|La richiesta non è andata a buon fine. Prova di nuovo tra qualche secondo.|%}")
                    reject()
                }
                btn.classList.remove("loading")
                vFields.forEach((el) => { el.removeAttribute("readonly") })


            }
        })
    }
}

ContactUsDialog.RegisterElement()